<template>
  <el-col class="h-100">
    <el-menu
      id="header-menu"
      :default-active="activeIndexMenu"
      background-color="#27A359"
      mode="horizontal"
    >
      <template v-if="!isTablet">
        <el-menu-item
          v-for="(item, i) in headerMenuItems"
          v-show="!!isAuth === item.auth"
          :key="i"
          :index="item.name"
          :class="`ta-c tt-up ${item.classes}`"
          @click="$router.push({ name: item.name })"
        >
          <div>
            <el-row type="flex" justify="center">
              {{ item.title }}
              <span v-if="item.counts" class="ml-5">({{ count }})</span>
            </el-row>
            <el-row v-if="item.span" type="flex" justify="center">
              {{ item.span }}
              <span v-if="item.active" class="ml-5">({{ activeTenders }})</span>
            </el-row>
          </div>
        </el-menu-item>
      </template>
      <el-submenu index="" v-else>
        <span slot="title" class="el-icon-own-menu-big" />
        <el-menu-item
          v-for="(item, i) in headerMenuItems"
          v-show="!!isAuth === item.auth"
          :key="i"
          :index="item.name"
          class="mt-5 mb-5 pt-5 pb-5"
          @click="$router.push({ name: item.name })"
        >
          {{ item.title }} {{ item.span }}
          <span v-if="item.counts" class="ml-5"> ({{ count }})</span>
          <span v-if="item.active" class="ml-5"> ({{ activeTenders }})</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-col>
</template>

<script>
import { mapGetters } from 'vuex'
import { headerMenuItems } from '@/data/menuItems'

export default {
  name: 'HeaderMenu',
  data() {
    return {
      headerMenuItems
    }
  },
  computed: {
    ...mapGetters({
      getCount: 'accreditations/getCount',
      activeTenders: 'tenders/getActiveCount'
    }),
    count() {
      return this.getCount(this.isBidder ? 'accepted' : 'pending')
    },
    activeIndexMenu() {
      return this.$route.name
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

#header-menu.el-menu--horizontal
  border: none
  height: 100%
  .el-menu-item
    display: flex
    align-items: center
    border: none
    height: 100%
    font-size: 18px
    line-height: 21px
    white-space: normal
    padding: 0 20px
    color: $white
    @include toH(700px)
      font-size: 14px
      line-height: 16px
    &.f-right
      float: right
    &.is-active, &:hover
      background-color: #fff !important
      color: $headerGreyColor
  .el-submenu
    height: 100%
    display: flex
    align-items: center
    .el-submenu__title
      @include toH(700px)
        height: 45px
        line-height: 45px
    &.is-active .el-submenu__title
      border: none
    .el-submenu__icon-arrow
      display: none
    .el-icon-own-menu-big
      width: 45px
      height: 40px
      cursor: pointer
      position: relative
      margin: 0
      @include toH(700px)
        width: 35px
        height: 30px
      &:before
        content: ''
        position: absolute
        width: 45px
        height: 40px
        background: url("~@/assets/imgs/icons/menu-icon-white.svg") no-repeat
        background-size: contain
        left: 0
        @include toH(700px)
          width: 35px
          height: 30px
.el-menu--horizontal .el-menu.el-menu--popup .el-menu-item
  color: $white
  &.is-active, &:not(.is-disabled):hover
    color: $white
    background-color: rgb(31, 130, 70) !important
</style>
