<template>
  <el-container id="app">
    <el-header height="auto">
      <i-header />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
    <el-footer>
      <i-footer height="75px" />
    </el-footer>
    <mobile-menu v-if="isMobile" />
  </el-container>
</template>

<script>
import IHeader from '@/components/Header'
import IFooter from '@/components/Footer'
import MobileMenu from '@/components/MobileMenu'

export default {
  name: 'App',
  components: { MobileMenu, IHeader, IFooter }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"
.el-header
  @include to(768px)
    background: $white
    position: sticky
    top: 0
    z-index: 2000
</style>
