<template>
  <el-row
    id="footer"
    type="flex"
    justify="space-between"
    align="middle"
    class="h-100 bg-accepted"
  />
</template>

<script>
export default {
  name: 'Footer'
}
</script>
