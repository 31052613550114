import GenerateStoreClass from '@/store/GenerateStoreClass'
import { errHandlerHelper } from '@/helpers/errorHandler'
import { getIsHelperShow, setIsHelperShow } from '@/helpers/localstorage'

const isHelperShown = getIsHelperShow()

class AccreditationsStore extends GenerateStoreClass {
  constructor(name) {
    super(name)
    this.factory.state = this.ownState()
    this.factory.getters = this.ownGetters()
    this.factory.actions = this.ownActions()
    this.factory.mutations = this.ownMutations()
  }

  ownState = () => ({
    ...this.factory.state,
    isHelperShown: isHelperShown,
    currentStatus: '',
    counts: {
      total: 0,
      accepted: 0,
      ok_expires_soon: 0,
      pending: 0,
      declined: 0,
      expired: 0
    },
    loadingCounts: false
  })

  ownGetters = () => ({
    ...this.factory.getters,
    getCount: state => key => state.counts[key],
    currentStatus: state => state.currentStatus,
    isItems: state => !!state.counts?.total?.length,
    showHelper: state => !state.isHelperShown && !state.loading
  })

  ownActions = () => ({
    ...this.factory.actions,
    FETCH_COUNTS: ({ commit }) => {
      commit(this.TYPES.LOADING, { flag: true, type: 'Counts' })
      this.Api.fetchCounts()
        .then(res => commit('UPDATE_COUNTS', res.data))
        .catch(e => errHandlerHelper(e))
        .finally(() => commit(this.TYPES.LOADING, { type: 'Counts' }))
    }
  })

  ownMutations = () => ({
    ...this.factory.mutations,
    setHelperShown: state => {
      state.isHelperShown = 'shown'
      setIsHelperShow('shown')
    },
    setCurrentStatus: (state, status) => {
      state.currentStatus = status
    },
    UPDATE_COUNTS: (state, data) => {
      state.counts = data
    }
  })
}

const store = new AccreditationsStore('accreditations')

export default store.factory
