<template>
  <el-button
    :type="type"
    :icon="icon"
    round
    :loading="loading"
    :disabled="disabled"
    :class="`fs-${fs} ${customClass}`"
    :style="{ width: width + 'px' }"
    @click="$emit('click')"
  >
    {{ text }}
    <slot />
  </el-button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '200'
    },
    type: {
      type: String,
      default: 'success'
    },
    icon: {
      type: String,
      default: '20'
    },
    fs: {
      type: String,
      default: '20'
    },
    customClass: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
