<template>
  <div class="user-info">
    <span class="el-icon-user-icon"></span>
    <template v-if="isAuth">
      <span class="fs-18 fw-b accepted">{{ userFullName }}</span>
      <span class="el-icon-user-change" @click="toProfile"></span>
    </template>
    <template v-else>
      <ButtonSecondary @click="onEnter" text="ВХОД" />
      <ButtonSecondary @click="goToReg" text="РЕГИСТРАЦИЯ" />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ButtonSecondary from '@/components/Ui/ButtonSecondary'

export default {
  name: 'MobileMenuUserInfo',
  components: { ButtonSecondary },
  computed: {
    ...mapGetters('profile', ['userFullName'])
  },
  methods: {
    ...mapMutations({
      setDialog: 'popups/setDialog',
      setAside: 'popups/setAside'
    }),
    goToReg() {
      this.$router.push({ name: 'Registration' })
      this.setAside(false)
    },
    onEnter() {
      this.setDialog({ name: 'enter-modal', visible: true })
    },
    toProfile() {
      this.$router.push('/profile')
      this.setAside(false)
    }
  }
}
</script>

<style lang="sass">
.user-info
  padding: 19px 4px 8px
  border-bottom: 1px solid #DADADA
  display: flex
  justify-content: flex-start
  align-items: center

  .el-icon-user-icon
    width: 21px
    height: 24px
    margin-right: 27px
    &:before
      content: ''
      position: absolute
      width: 21px
      height: 24px
      background: url("~@/assets/imgs/icons/user-icon.svg") no-repeat

  .el-icon-user-change
    width: 14px
    height: 13px
    margin-left: 30px
    &:before
      content: ''
      position: absolute
      width: 14px
      height: 13px
      background: url("~@/assets/imgs/icons/edit-icon-green.svg") no-repeat
</style>
