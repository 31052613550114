import ApiFactory from '@/api/ApiFactory'
import { SET_LIB } from '../actions/libs'
import { arrToObj } from '@/helpers/functions'
import { errHandlerHelper } from '@/helpers/errorHandler'

const LibRepository = ApiFactory.get('libs')

export default {
  namespaced: true,
  state: {
    'load-types': null,
    organizations: null
  },
  getters: {
    getLib: state => lib => state[lib] || {},
    getOrgName: state => id =>
      state.organizations ? state.organizations[id]?.name : '',
    checkLibs: state => state['load-types'] && state.organizations
  },
  actions: {
    fetchLibs({ state, commit }, settings) {
      Object.keys(state).forEach(libName => {
        LibRepository.getLib(libName, settings).then(({ data }) => {
          commit(SET_LIB, { data, libName })
        })
      })
    },
    fetchOrganizations(context, settings) {
      return LibRepository.getLib('organizations', settings)
        .then(({ data }) => data)
        .catch(e => errHandlerHelper(e))
    }
  },
  mutations: {
    [SET_LIB](state, { data, libName }) {
      if (data.length) state[libName] = arrToObj(data)
    }
  }
}
