<template>
  <el-col :xs="20" class="search-input ta-m-l">
    <span v-if="showTitle && !isMobile" class="mr-20">Искать</span>
    <div class="search-input__container">
      <el-input
        v-model="search_input"
        clearable
        style="width: 230px"
        autocomplete="nope"
        @clear="clearSearch"
        @keyup.enter.native="searching"
      />
      <div class="search-input-icon" @click="searching" />
    </div>
  </el-col>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      search_input: ''
    }
  },
  watch: {
    value(newVal) {
      this.search_input = newVal
    }
  },
  methods: {
    clearSearch() {
      this.$emit('change', 'any')
    },
    searching() {
      this.$emit('change', 'any', this.search_input)
    }
  }
}
</script>

<style lang="sass">
@import '~@/assets/styles/mixins.sass'

.search-input
  position: relative
  display: flex
  align-items: center
  flex-wrap: wrap
  justify-content: flex-end
  &__container
    flex: 0 0 auto
    display: flex
    justify-content: flex-start
    align-items: center
    @include to(1045px)
      flex: 0 0 100%
  & .search-input-icon
    position: relative
    width: 25px
    height: 25px
    margin-left: 20px
    @include to(768px)
      margin-bottom: 20px
    &:after
      content: ''
      position: absolute
      background: url('~@/assets/imgs/icons/search-icon.svg') no-repeat
      width: 25px
      height: 25px
      background-size: contain
      cursor: pointer
      right: 0
      top: 50%
      transform: translateY(-50%)
</style>
