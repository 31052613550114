<template>
  <el-dialog
    :visible="visible"
    :top="propsTable.top"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :width="propsTable.width"
    :custom-class="name"
    :fullscreen="fullscreen"
    @close="onClose"
  >
    <template #title>
      <slot name="title" />
      <span class="close-icon" @click="onClose" />
    </template>
    <slot />
    <template #footer>
      <slot name="footer" />
    </template>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Dialog',
  props: {
    name: {
      type: String,
      default: ''
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({ getDialog: 'popups/getDialog' }),
    visible() {
      return this.getDialog(this.name).visible || false
    },
    propsTable() {
      return this.isMobile || this.isTablet
        ? { top: '0vh', width: '100%' }
        : { top: '5vh', width: '90%' }
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    onClose() {
      const { name } = this
      this.setDialog({ name })
      this.$emit('close')
    }
  }
}
</script>
