const lsTokenKey = process.env.VUE_APP_LS_KEY + '-auth-token'
const lsAdminKey = process.env.VUE_APP_LS_KEY + '-admin-token'
const lsProfileKey = process.env.VUE_APP_LS_KEY + '-user-profile'
const lsHelperShownKey = process.env.VUE_APP_LS_KEY + '-helper-shown'

export const getToken = () => localStorage.getItem(lsTokenKey)
export const getAdminToken = () => localStorage.getItem(lsAdminKey)
export const getIsHelperShow = () => localStorage.getItem(lsHelperShownKey)
export const getUserProfile = () =>
  JSON.parse(localStorage.getItem(lsProfileKey))

export const setToken = token => localStorage.setItem(lsTokenKey, token)
export const setAdminToken = token => localStorage.setItem(lsAdminKey, token)
export const setIsHelperShow = token =>
  localStorage.setItem(lsHelperShownKey, token)
export const setUserProfile = profile =>
  localStorage.setItem(lsProfileKey, JSON.stringify(profile))

export const removeTokens = () => {
  localStorage.removeItem(lsTokenKey)
  localStorage.removeItem(lsAdminKey)
}

export const removeUserProfile = () => {
  localStorage.removeItem(lsProfileKey)
  localStorage.removeItem(lsHelperShownKey)
}
