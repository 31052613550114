import router from '@/router'
import ApiFactory from '@/api/ApiFactory'
import { errHandlerHelper } from '@/helpers/errorHandler'
import { roles } from '@/data/constants'
import * as action from '../actions/profile'
import { AUTH_SUCCESS } from '../actions/auth'
import {
  getUserProfile,
  removeUserProfile,
  setToken,
  setUserProfile
} from '@/helpers/localstorage'

const ProfileApi = ApiFactory.get('profile')
const profile = getUserProfile()
const initialState = {
  id: 0,
  login: '',
  email: '',
  org_id: '',
  email_verified: '',
  first_name: '',
  last_name: '',
  user_role: '',
  organization: null
}

export default {
  namespaced: true,
  state: profile ? profile : { ...initialState },
  getters: {
    userInfo: state => state,
    userRole: state => state.user_role,
    userFullName: state => {
      const { first_name, last_name } = state
      let name = first_name
      if (first_name && last_name) name += ' '
      if (last_name) name += last_name + '.'
      return name
    }
  },
  actions: {
    async getProfile({ commit, dispatch }) {
      return await ProfileApi.getProfile()
        .then(res => {
          if (!res.data || !roles.includes(res.data.user_role)) {
            dispatch('auth/logout', null, { root: true })
            errHandlerHelper('Недопустимая роль пользователя!')
            return 'error'
          } else commit(action.SET_PROFILE, res.data)
        })
        .catch(err => console.log(err))
    },
    async changeProfile({ commit, dispatch }, newToken) {
      setToken(newToken)
      commit(action.CLEAR_PROFILE)
      dispatch('getProfile').then(res => {
        if (res !== 'error') {
          commit(`auth/${AUTH_SUCCESS}`, newToken, { root: true })
        }
        router.push({ name: 'Profile' })
      })
    }
  },
  mutations: {
    [action.SET_PROFILE]: (state, data) => {
      Object.assign(state, data)
      state = { ...state, ...data }
      setUserProfile(state)
    },
    [action.CLEAR_PROFILE]: state => {
      removeUserProfile()
      Object.assign(state, initialState)
    }
  }
}
