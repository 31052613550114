export const headerMenuItems = [
  { name: 'Description', title: 'Описание', span: 'сервиса', auth: false },
  { name: 'ForCarriers', title: 'Для', span: 'перевозчиков', auth: false },
  { name: 'ForCustomers', title: 'Для', span: 'заказчиков', auth: false },
  { name: 'Help', title: 'Помощь', span: 'в работе сервиса', auth: true },
  {
    name: 'Tenders',
    title: 'Активные',
    span: 'тендеры',
    active: true,
    auth: true
  },
  { name: 'ArchiveTenders', title: 'Архив', span: 'тендеров', auth: true },
  {
    name: 'Accreditations',
    title: 'Аккредитация',
    counts: true,
    classes: 'f-right',
    auth: true
  }
]
