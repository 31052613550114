/** date & time **/
export const renderTimer = (timer, full = false, clock = true) => {
  if (!timer) return ''
  let days, hours
  if (full) {
    days = Math.floor(timer / (24 * 60 * 60))
    timer -= days * 60 * 60 * 24
    hours = Math.floor(timer / (60 * 60))
    timer -= hours * 60 * 60
  }
  const minute = Math.floor((timer / 60) % 60)
  const second = Math.floor(timer % 60)
  let time = ''
  if (full) {
    if (days) time += days + ' д '
    if (hours || days)
      time += (hours < 10 ? 0 : '') + hours + (clock ? ' : ' : ' ч ')
  }
  if (minute < 10) time += 0
  time += minute + (clock ? ' : ' : ' м ')
  if (clock) {
    if (second < 10) time += 0
    time += second
  }

  return time
}

const dateFormats = {
  default: {},
  short: {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  },
  shortWithHours: {
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit'
  },
  fullWithHours: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }
}

export const isValidDate = date => new Date(date).toString() !== 'Invalid Date'

export const toLocaleDateTime = (
  date,
  format = 'default',
  timestamp = true
) => {
  return date
    ? new Date(timestamp ? date * 1000 : date).toLocaleDateString(
        'ru-RU',
        dateFormats[format]
      )
    : ''
}

/** array & object **/
export function toArray(array, data) {
  let arr = !Array.isArray(array) ? [] : array

  if (typeof data === 'object') {
    const index = arr.findIndex(i => i.id === data.id)
    if (index > -1) {
      const el = arr[index]
      arr.splice(index, 1)
      arr.unshift(el)
    } else {
      arr.unshift(data)
      if (data.relation) {
        let n = 0
        arr = arr.filter(i => {
          if (i.relation === data.relation) {
            if (n === 2) return false
            n++
          }
          return true
        })
      }
    }
  }
  const count = data.relation ? 16 : 8

  return arr.slice(0, count)
}

export const arrToObj = arr => {
  const array = arr || [],
    obj = {}
  array.map(i => (obj[i.id] = i))

  return obj
}

export const dataToObj = (obj, data) => {
  const newObj = { ...obj }
  for (let k in newObj) {
    if (!newObj.hasOwnProperty(k)) continue
    if (k.includes('utime_') || k === 'contract_expire_date')
      newObj[k] = data[k] * 1000
    else newObj[k] = data[k]
  }

  return newObj
}

export const updateObjFromData = (obj, data) => {
  const newObj = { ...obj }
  for (let k in newObj) {
    if (!newObj.hasOwnProperty(k)) continue
    if (data[k] !== undefined) newObj[k] = data[k]
  }

  return newObj
}

/** request **/
export const getParamsUrl = settings => {
  let params = ''
  const config = settings ?? {}
  for (let key in config) {
    if (!!settings[key] || settings[key] === 0) {
      params += params ? '&' : '?'
      params += `${key}=${settings[key]}`
    }
  }
  return params
}

/** preparation for format **/
export const phonePreparation = phone => {
  return phone ? phone.replace(/\D/g, '') : ''
}

export const phoneRegex = phone => {
  if (!phone || phone.length !== 11) return ''
  const m = phone.match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/)
  return `+${m[1]} (${m[2]}) ${m[3]}-${m[4]}-${m[5]}`
}

export const numToLocaleString = num => {
  if (!num || typeof num !== 'number') return num
  return Number(num).toLocaleString()
}

export const addressPreparation = (region, district, city, address) => {
  let result = region
  if (district) result += (result ? ', ' : '') + district
  if (city) result += (result ? ', ' : '') + city
  if (address) result += (result ? ', ' : '') + address
  return result
}

export const helperGetDay = (num, type = 'min') => {
  const periodTypes = {
    min: 'минута_минуты_минут',
    h: 'час_часа_часов',
    d: 'день_дня_дней',
    m: 'месяц_месяца_месяцев',
    y: 'год_года_лет'
  }
  const forms = periodTypes[type].split('_')
  const text =
    num % 10 === 1 && num % 100 !== 11
      ? forms[0]
      : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
      ? forms[1]
      : forms[2]

  return num ? `${num} ${text}` : ''
}
