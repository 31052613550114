import Vue from 'vue'
import Vuex from 'vuex'
import GenerateStoreClass from './GenerateStoreClass'

import popups from './modules/popups'
import libs from './modules/libs'
import auth from './modules/auth'
import profile from './modules/profile'
import accreditations from './modules/accreditations'
import tenders from './modules/tenders'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    libs,
    auth,
    profile,
    popups,
    accreditations,
    tenders,
    users: new GenerateStoreClass('users').factory,
    offers: new GenerateStoreClass('offers').factory,
    products: new GenerateStoreClass('products').factory,
    consignees: new GenerateStoreClass('consignees').factory,
    biddersGroups: new GenerateStoreClass('biddersGroups').factory,
    approvedUsers: new GenerateStoreClass('approvedUsers').factory,
    invitations: new GenerateStoreClass('invitations').factory
  },
  strict: process.env.NODE_ENV !== 'production'
})
