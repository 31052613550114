import GenerateStoreClass from '@/store/GenerateStoreClass'
import { errHandlerHelper } from '@/helpers/errorHandler'

class TendersStore extends GenerateStoreClass {
  constructor(name) {
    super(name)
    this.factory.state = this.ownState()
    this.factory.getters = this.ownGetters()
    this.factory.actions = this.ownActions()
    this.factory.mutations = this.ownMutations()
  }

  ownState = () => ({
    ...this.factory.state,
    loadingCounters: false,
    count_active: 0
  })

  ownGetters = () => ({
    ...this.factory.getters,
    getActiveCount: state => state.count_active
  })

  ownActions = () => ({
    ...this.factory.actions,
    UPDATE_COUNTERS: ({ commit, state }) => {
      commit(this.TYPES.LOADING, { flag: true, type: 'Counters' })
      const tender_id = state.tenders.flatMap(item => item.id)
      if (!tender_id.length) {
        return commit(this.TYPES.LOADING, { type: 'Counters' })
      }
      this.Api.updateCounters({ tender_id })
        .then(res => commit('UPDATE_TENDER_COUNTER', res.data))
        .catch(e => errHandlerHelper(e))
        .finally(() => commit(this.TYPES.LOADING, { type: 'Counters' }))
    },
    FETCH_TOTAL_COUNTERS: ({ commit }) => {
      this.Api.getTotalCounters()
        .then(res => commit('UPDATE_TOTAL_COUNTERS', res.data))
        .catch(e => errHandlerHelper(e))
    }
  })

  ownMutations = () => ({
    ...this.factory.mutations,
    UPDATE_TENDER_COUNTER(state, data) {
      state.tenders.map(item => {
        if (data[item.id]) {
          item.counter_best_price = data[item.id].best_price
          item.counter_bidders_count = data[item.id].bidders_count
          item.counter_my_price = data[item.id].my_price
          item.counter_my_price_is_winning = data[item.id].my_price_is_winning
        }
      })
    },
    UPDATE_TOTAL_COUNTERS(state, data) {
      state.count_active = data?.count_active || 0
    }
  })
}

const store = new TendersStore('tenders')

export default store.factory
