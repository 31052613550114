<template>
  <i-dialog :name="name" fullscreen>
    <el-row
      slot="title"
      type="flex"
      justify="space-between"
      align="middle"
      class="w-100 f-m-wrap"
    >
      <el-col class="fs-20 m-0">СПИСОК ПОЛЬЗОВАТЕЛЕЙ</el-col>
      <el-col v-if="isAnotherUser" class="fs-20 t-greenLight m-0">
        <span class="bb cp" @click="backToAdmin">вернуться к админу</span>
      </el-col>
    </el-row>
    <section class="bottom-section">
      <div class="pagination">
        <i-pagination show :params="pagination" @changed="changePage" />
      </div>
    </section>
    <el-table :data="usersList" v-loading="isLoading" style="width: 100%">
      <el-table-column
        label="ФИО"
        prop="fio"
        header-align="center"
        :formatter="formatter"
        align="center"
      />
      <el-table-column label="Телефон" header-align="center" align="center">
        <template slot-scope="scope">
          <input
            class="tamt-login ta-c"
            v-mask="'+7 (###) ###-##-##'"
            :value="scope.row.login"
            masked="true"
            readonly
          />
        </template>
      </el-table-column>
      <el-table-column align="right">
        <search-input
          slot="header"
          :value="any"
          :showTitle="false"
          @change="onChangeSearch"
        />
        <template slot-scope="scope">
          <i-button
            :loading="isLoading"
            :disabled="isLoading"
            text="Выбрать"
            width="120"
            fs="16"
            custom-class="small-btn"
            @click="handleSelect(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </i-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ApiFactory from '@/api/ApiFactory'
import IPagination from '@/components/Ui/Pagination'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'
import SearchInput from '@/components/Ui/SearchInput'

const Users = ApiFactory.get('users')

export default {
  name: 'UsersListModal',
  components: { IPagination, IDialog, IButton, SearchInput },
  data() {
    return {
      name: 'users-list-modal',
      any: '',
      curPage: 1
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getUsers()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getLoading: 'users/getLoading',
      pagination: 'users/getPagination',
      usersList: 'users/getList',
      getDialog: 'popups/getDialog'
    }),
    isLoading() {
      return this.getLoading()
    },
    visible() {
      return this.getDialog(this.name).visible
    }
  },
  methods: {
    ...mapActions({
      FETCH: 'users/FETCH',
      changeProfile: 'profile/changeProfile'
    }),
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    handleSelect(row) {
      Users.getUserToken({ user_id: row.id }).then(res => {
        const { status, data } = res
        if (status === 200 && data.token) {
          this.changeProfile(data.token)
          this.setDialog({ name: this.name })
        } else {
          this.$message({
            showClose: true,
            message: 'Произошла ошибка при смене пользователя',
            type: 'error',
            duration: 5000
          })
        }
      })
    },
    formatter(row) {
      return `${row.first_name} ${row.last_name}.`
    },
    backToAdmin() {
      this.changeProfile(this.adminToken)
      this.setDialog({ name: this.name })
    },
    getUsers() {
      this.FETCH({ page: this.curPage, any: this.any, 'per-page': 10 })
    },
    clearSearch() {
      this.any = ''
      this.changePage()
    },
    changePage(page = 1) {
      this.curPage = page
      this.getUsers()
    },
    onChangeSearch(key, val = undefined) {
      this[key] = val
      this.getUsers()
    }
  }
}
</script>

<style lang="sass">
.el-dialog.users-list-modal
  .el-pagination
    margin-top: 10px
  .el-dialog__body
    padding: 0 20px
    .cell
      font-size: 16px
      line-height: 1
    th
      padding: 5px 0
    td
      padding: 10px 0
  .search-input
    .el-input__inner
      background: $white
      &:focus
        border-color: $white
    .search-input-icon:after
      background: url('~@/assets/imgs/icons/search-icon-white.svg') no-repeat
</style>
