export const enterFormRules = {
  code: {
    required: true,
    message: 'Код введен не полностью',
    trigger: 'blur'
  },
  phone: [
    {
      required: true,
      message: 'Необходимо заполнить телефон',
      trigger: ['blur', 'change']
    },
    {
      min: 18,
      message: 'Телефон заполнен не полностью',
      trigger: ['blur']
    }
  ]
}
