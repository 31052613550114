<template>
  <i-dialog name="enter-modal" @close="onClose">
    <p slot="title">ВХОД ДЛЯ ДОСТУПА К ТЕНДЕРАМ</p>
    <el-form
      ref="enterForm"
      :model="form"
      :rules="rules"
      label-position="left"
      class="enter-form i-components"
      @submit.native.prevent
      @keyup.enter.native="onClick"
    >
      <el-form-item prop="phone">
        <el-input
          v-model="form.phone"
          v-mask="'+7 (###) ###-##-##'"
          placeholder="Телефон для входа"
          prefix-icon="user-icon"
          autocomplete="nope"
          class="phone"
        />
      </el-form-item>
      <el-form-item
        v-if="isSent"
        :error="authStatus"
        prop="code"
        :label="labelCode"
        :label-width="isMobile ? '0px' : '130px'"
      >
        <el-input
          v-model="form.code"
          v-mask="'######'"
          autocomplete="nope"
          :placeholder="placeholderCode"
          @input="onInput"
        />
      </el-form-item>
      <el-row
        type="flex"
        align="middle"
        :justify="isSent ? 'space-between' : 'end'"
        class="mt-50 f-wrap"
      >
        <div v-if="isSent" class="fs-20 t-greyDark">
          <span v-if="counter > 0">Выслать код повторно можно через</span>
          <span v-else class="td-u cp" @click="sendingSms">
            Выслать код повторно
          </span>
          <component :is="isMobile ? 'span' : 'p'" class="ta-c m-0">
            {{ counter | toTimer }}
          </component>
        </div>
        <div class="w-m-100 mt-m-20">
          <i-button
            :text="isSent ? 'Вход' : 'Далее'"
            :loading="loading"
            :disabled="loading"
            width="120"
            @click="onClick"
          />
        </div>
      </el-row>
    </el-form>
  </i-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'
import { enterFormRules } from '../constants'

export default {
  name: 'EnterModal',
  components: { IDialog, IButton },
  data() {
    return {
      rules: enterFormRules,
      form: {
        phone: '',
        code: ''
      },
      counter: 0,
      isSent: false,
      timer: null
    }
  },
  computed: {
    ...mapGetters('auth', ['authStatus', 'getLoading']),
    loading() {
      return this.getLoading() || this.getLoading('Login')
    },
    placeholderCode() {
      return this.isMobile ? 'SMS код' : ''
    },
    labelCode() {
      return this.isMobile ? '' : 'SMS код'
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'sendSms']),
    ...mapMutations('auth', ['setStatus']),
    setCounter(counter) {
      this.counter = counter
      this.checkTimer()
      this.isSent = true
    },
    onInput() {
      if (this.authStatus) this.setStatus()
    },
    onClick() {
      this.$refs.enterForm.validate(valid => {
        if (valid) {
          if (!this.isSent) {
            this.sendingSms()
          } else {
            const { code, phone: login } = this.form
            this.login({ code, login })
          }
        }
      })
    },
    checkTimer() {
      if (this.counter) {
        this.timer = setTimeout(() => {
          --this.counter
          this.checkTimer()
        }, 1000)
      }
    },
    sendingSms() {
      if (!this.loading) {
        this.sendSms(this.form.phone)
          .then(data => {
            const { text, status, time_to_resend } = data || {}
            this.$notify.closeAll()
            if (status === 'error') this.errHandler(text)
            else if (text) {
              this.$notify({
                message: text,
                title: 'Отправлено',
                type: 'success',
                duration: 0
              })
            }
            this.setCounter(time_to_resend)
          })
          .catch(err => this.errHandler(err))
      }
    },
    onClose() {
      this.counter = 0
      this.isSent = false
      clearTimeout(this.timer)
      this.setStatus()
      this.$notify.closeAll()
      this.$refs.enterForm.resetFields()
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="sass">
.el-dialog.enter-modal
  max-width: 700px
  .enter-form
    .phone input
      padding-left: 70px
    input
      background: #E8E8E8
</style>
