import Repository from './Repository'
import { phonePreparation } from '@/helpers/functions'

const action = 'auth'

export default {
  sms(phone) {
    phone = phonePreparation(phone)
    return Repository.post(`${action}/send-sms-code`, { phone })
  },
  preRegister({ login, ...data }) {
    login = phonePreparation(login)
    return Repository.post(`${action}/pre-validate-register`, {
      login,
      ...data
    })
  },
  register({ login, ...data }) {
    login = phonePreparation(login)
    return Repository.post(`${action}/register`, { login, ...data })
  },
  login(login, code) {
    login = phonePreparation(login)
    return Repository.post(`${action}/login`, { login, code })
  }
}
