import ApiFactory from '@/api/ApiFactory'
import { errHandlerHelper } from '@/helpers/errorHandler'
import { updateObjFromData } from '@/helpers/functions'
import { getLoading, setLoading } from './common'

export default class GenerateStoreClass {
  constructor(name) {
    this.name = name
    this.nameUpper = name.toUpperCase()
    this.Api = ApiFactory.get(name)
    this.TYPES = this.getTypes()
    this.factory = {
      namespaced: true,
      state: this.getState(),
      getters: this.getGetters(),
      actions: this.getActions(),
      mutations: this.getMutations()
    }
  }

  getTypes = () => ({
    LOADING: `LOADING_${this.nameUpper}`,
    SET: `SET_${this.nameUpper}`,
    UPDATE: `UPDATE_${this.nameUpper}`,
    DELETE: `DELETE_${this.nameUpper}`
  })

  getState = () => ({
    [this.name]: [],
    pagination: {},
    loading: false
  })

  getGetters = () => ({
    getLoading,
    getList: state => state[this.name],
    getById: state => id => state[this.name].find(item => item.id === id),
    getPagination: state => state.pagination
  })

  getActions = () => ({
    CREATE: ({ commit, dispatch }, data) => {
      commit(this.TYPES.LOADING, { flag: true })
      const { fetch, ...requestData } = data
      return this.Api.createItem(requestData)
        .then(res => {
          if ([200, 201].includes(res.status)) {
            if (fetch) {
              dispatch('FETCH', fetch)
            } else {
              commit(this.TYPES.UPDATE, res.data)
              return res.data
            }
          } else {
            return errHandlerHelper('Ошибка создания')
          }
        })
        .catch(e => errHandlerHelper(e))
        .finally(() => commit(this.TYPES.LOADING))
    },
    UPDATE: ({ commit, dispatch }, { id, data }) => {
      commit(this.TYPES.LOADING, { flag: true })
      const { fetch, ...requestData } = data
      return this.Api.updateItem(id, requestData)
        .then(res => {
          if (res.status === 200) {
            if (fetch) {
              dispatch('FETCH', fetch)
            } else {
              commit(this.TYPES.UPDATE, res.data)
            }
          } else {
            return errHandlerHelper('Ошибка обновления')
          }
        })
        .catch(e => errHandlerHelper(e))
        .finally(() => commit(this.TYPES.LOADING))
    },
    FETCH: ({ commit }, data) => {
      commit(this.TYPES.LOADING, { flag: true })
      this.Api.getList(data)
        .then(({ data, headers }) => {
          const pagination = {
            currentPage: parseInt(headers['x-pagination-current-page']),
            pageCount: parseInt(headers['x-pagination-page-count']),
            perPage: parseInt(headers['x-pagination-per-page']),
            totalCount: parseInt(headers['x-pagination-total-count'])
          }
          commit(this.TYPES.SET, { data, pagination })
        })
        .catch(e => errHandlerHelper(e))
        .finally(() => commit(this.TYPES.LOADING))
    },
    DELETE: ({ commit }, id) => {
      commit(this.TYPES.LOADING, { flag: true })
      return this.Api.deleteItem(id)
        .then(res => {
          if (res.status === 204) {
            commit(this.TYPES.DELETE, res.data)
          } else {
            return errHandlerHelper('Ошибка удаления')
          }
        })
        .catch(e => errHandlerHelper(e))
        .finally(() => commit(this.TYPES.LOADING))
    }
  })

  getMutations = () => ({
    [this.TYPES.LOADING]: setLoading,
    [this.TYPES.SET]: (state, { data, pagination }) => {
      state[this.name] = data
      state.pagination = pagination
    },
    [this.TYPES.UPDATE]: (state, data) => {
      if (data) {
        const index = state[this.name].findIndex(item => item.id === data.id)
        if (index >= 0) {
          const newData =
            Object.keys(state[this.name][index]).length ===
            Object.keys(data).length
              ? data
              : updateObjFromData(state[this.name][index], data)

          state[this.name].splice(index, 1, newData)
        } else state[this.name].push(data)
      }
    },
    [this.TYPES.DELETE]: (state, id) => {
      state[this.name] = state[this.name].filter(d => d.id !== id)
    }
  })
}
