<template>
  <el-button
    :class="`fs-${size}`"
    :style="{ width: width + 'px' }"
    @click="$emit('click')"
    class="secondary"
  >
    {{ text }}
  </el-button>
</template>

<script>
export default {
  name: 'ButtonSecondary',
  props: {
    text: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: 'auto'
    },
    size: {
      type: String,
      default: '20'
    }
  }
}
</script>
