import axios from 'axios'
import router from '@/router'
import {
  removeUserProfile,
  removeTokens,
  getAdminToken,
  getToken
} from '@/helpers/localstorage'

const isProd = process.env.NODE_ENV === 'production'

const baseURL = isProd
  ? process.env.VUE_APP_API_URL_PROD
  : process.env.VUE_APP_API_URL

const ax = axios.create({ baseURL, timeout: 30000 })

ax.interceptors.request.use(
  config => {
    const token = config.admin ? getAdminToken() : getToken()
    const newConfig = config
    if (token) newConfig.headers.Authorization = `Bearer ${token}`

    return newConfig
  },
  err => Promise.reject(err)
)

ax.interceptors.response.use(
  res => res,
  async error => {
    if (error.response && 401 === error.response.status) {
      removeTokens()
      removeUserProfile()
      if (location.pathname !== '/') location.href = '/'

      if (typeof window?.location?.reload === 'function') {
        window.location.reload()
      } else if (typeof document?.location?.reload === 'function') {
        document.location.reload(true)
      } else {
        await router.push('/login')
      }
    }

    if (error.response && 422 === error.response.status) {
      return Promise.reject(error)
    }
    return Promise.reject(error.response)
  }
)

export default ax
