<template>
  <el-drawer
    :visible.sync="menuVisible"
    direction="ltr"
    size="100%"
    :with-header="false"
    class="mobile-menu"
  >
    <MobileMenuUserInfo />
    <div class="mobile-menu__items">
      <MobileMenuItem v-for="item in menuItems" :key="item.name" :item="item" />
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { headerMenuItems } from '@/data/menuItems'
import MobileMenuUserInfo from './components/MobileMenuUserInfo'
import MobileMenuItem from './components/MobileMenuItem'

export default {
  name: 'MobileMenu',
  components: { MobileMenuItem, MobileMenuUserInfo },
  computed: {
    ...mapGetters('popups', ['isAsideOpen']),
    menuItems() {
      return headerMenuItems.filter(item => !!this.isAuth === item.auth)
    },
    menuVisible: {
      get() {
        return this.isAsideOpen
      },
      set(newVal) {
        this.setAside(newVal)
      }
    }
  },
  methods: {
    ...mapMutations('popups', ['setAside'])
  }
}
</script>

<style lang="sass">
.mobile-menu
  .el-drawer__body
    padding: 70px 20px 20px
  &__items
    padding: 30px 0 10px
    max-height: calc(100vh - 52px - 70px)
    overflow: auto
</style>
