import Repository from './Repository'
import { getParamsUrl } from '@/helpers/functions'

export const commonApiActions = (action, additionalEndPoints = {}) => ({
  getList: params => Repository.get(action + getParamsUrl(params)),
  createItem: data => Repository.post(action, data),
  updateItem: (id, data) => Repository.put(`${action}/${id}`, data),
  deleteItem: id => Repository.delete(`${action}/${id}`),
  ...additionalEndPoints
})
