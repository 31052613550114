export const roles = ['bidder', 'vendor', 'admin']

export const pickerOptions = {
  firstDayOfWeek: 1,
  disabledDate(time) {
    return time.getTime() < new Date().setHours(0, 0, 0, 0)
  }
}

export const autocompleteConfigLoad = {
  type: 'load',
  title: 'Место погрузки',
  region: {
    keyName: ['name', ' ', 'typeShort'],
    params: { contentType: 'region' }
  },
  kladr: {
    keyName: ['typeShort', ' ', 'name'],
    params: { contentType: 'city', withParent: 1, regionId: null }
  }
}

export const autocompleteConfigUnload = {
  type: 'unload',
  title: 'Место выгрузки:',
  region: {
    keyName: ['name', ' ', 'typeShort'],
    params: { contentType: 'region' }
  },
  kladr: {
    keyName: ['typeShort', ' ', 'name'],
    params: { contentType: 'city', withParent: 1, regionId: null }
  }
}
