<template>
  <section>
    <div :class="getAdaptClass('l', 'logo container')">
      <span class="accepted">кто</span>Везет
      <span class="dop ml-20 mr-20"> - </span>
      <span class="dop">
        сервис торгов по перевозке сельскохозяйственных грузов
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.logo
  display: flex
  align-items: center
  height: 110px
  font-size: 60px
  line-height: 70px
  color: #6b6b6b
  font-weight: bold
  @include toH(700px)
    height: 65px
    font-size: 45px
    line-height: 53px
  .dop
    font-size: 30px
    line-height: 35px
    @include toH(700px)
      font-size: 20px
      line-height: 24px
  &.l-mob
    font-size: 30px
    line-height: 35px
    padding: 0 20px
    height: auto
    .dop
      font-size: 14px
      line-height: 17px
      @include to(620px)
        display: none
</style>
