import Repository from '@/api/Repository'
import { getParamsUrl } from '@/helpers/functions'

export default {
  confirmEmail: data => Repository.post('/users/email-confirm', data),
  requestConfirmEmail: () => Repository.post('/users/email-confirm-request'),
  getList: params =>
    Repository.get('/users' + getParamsUrl(params), { admin: true }),
  getUserToken: data =>
    Repository.post('/users/masked-auth', data, { admin: true })
}
