const routes = [
  {
    path: '/',
    redirect: '/description'
  },
  {
    path: '/email-confirmation',
    name: 'EmailConfirmation',
    component: () => import('@/views/EmailConfirmation')
  },
  {
    path: '/description',
    name: 'Description',
    component: () => import('@/views/Description')
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('@/views/Registration')
  },
  {
    path: '/for-carriers',
    name: 'ForCarriers',
    component: () => import('@/views/ForCarriers')
  },
  {
    path: '/for-customers',
    name: 'ForCustomers',
    component: () => import('@/views/ForCustomers')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile'),
    auth: true
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings'),
    auth: true
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/views/Help'),
    auth: true
  },
  {
    path: '/tenders',
    name: 'Tenders',
    component: () => import('@/views/Tenders'),
    auth: true
  },
  {
    path: '/tenders-add',
    name: 'TenderForm',
    component: () => import('@/components/Tender/TenderForm'),
    auth: true
  },
  {
    path: '/tenders-edit/:id',
    name: 'TenderEdit',
    component: () => import('@/components/Tender/TenderForm'),
    auth: true
  },
  {
    path: '/archive-tenders',
    name: 'ArchiveTenders',
    component: () => import('@/views/ArchiveTenders'),
    auth: true
  },
  {
    path: '/invitations',
    name: 'Invitations',
    component: () => import('@/views/Invitations'),
    auth: true
  },
  {
    path: '/accreditations',
    name: 'Accreditations',
    component: () => import('@/views/Accreditations'),
    auth: true
  },
  {
    path: '/accreditation-add',
    name: 'AccreditationAdd',
    component: () => import('@/views/Accreditations/AccreditationAdd'),
    auth: true
  },
  {
    path: '/bidders-groups',
    name: 'BiddersGroups',
    component: () => import('@/views/BiddersGroups'),
    auth: true
  }
]

export default routes
