import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { getToken } from '@/helpers/localstorage'

/** Обработка ошибок при дублировании роута **/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  duplicateNavigationPolicy: 'ignore',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const route = routes.find(r => r.path === to.path)
  if (route && !['/registration', '/email-confirmation'].includes(to.path)) {
    const token = getToken()
    if (!token && route.auth) return next('/registration')
    if (token && !route.auth) return next('/profile')
  }

  next()
})

export default router
