<template>
  <el-col
    :class="[
      'auth-block user-icon top10 ml-20 accepted',
      { cp: isAuth, 'ab-tab': isTablet }
    ]"
    @click.native="toProfile"
  >
    <div v-if="isAuth" class="ta-r">
      {{ userFullName }}
      <i
        v-if="adminToken"
        class="el-icon-magic-stick"
        :data-admin="true"
        @click="openUsersModal"
      />
    </div>
    <div v-else class="not-auth">
      <span @click="onEnter">ВХОД</span>
      <span @click="goToReg">РЕГИСТРАЦИЯ</span>
    </div>
  </el-col>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AuthBlock',
  computed: {
    ...mapGetters('profile', ['userFullName'])
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    goToReg() {
      this.$router.push({ name: 'Registration' })
    },
    onEnter() {
      this.setDialog({ name: 'enter-modal', visible: true })
    },
    toProfile(e) {
      if (this.isAuth && !e.target.dataset.admin) {
        this.$router.push('/profile')
      }
    },
    openUsersModal() {
      this.setDialog({ name: 'users-list-modal', visible: true })
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.auth-block
  background: #fff
  border-radius: 10px
  padding: 15px 30px 15px 60px
  max-width: 300px
  float: right
  font-size: 18px
  line-height: 24px
  @include toH(700px)
    font-size: 14px
    line-height: 17px
    padding: 10px 30px 10px 60px
  &.ab-tab
    max-width: 250px
    font-size: 15px
  .not-auth
    display: flex
    justify-content: space-between
  span
    cursor: pointer
    &:hover
      font-weight: bold
  &.user-icon.top10:before
    @include toH(700px)
      top: 7px
      width: 18px
      height: 20px
</style>
