<template>
  <section :class="getAdaptClass('it', 'info-top')">
    <div class="info-block container">
      <p v-if="!isMobile" class="m-0 fw-l">
        Наш сервис работает в тестовом режиме, сообщите нам об ошибке и мы ее
        быстро исправим
      </p>
      <p class="tel-email m-0">
        <span class="ws-nowrap">
          <span v-if="!isMobile">поддержка:</span>
          <span>+7 918 667-02-69,</span>
        </span>
        <span class="ws-nowrap">
          <span v-if="!isMobile">почта:</span>
          <span>support@kto-vezet.ru</span>
        </span>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InfoTop'
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.info-top
  font-size: 15px
  line-height: 18px
  color: $headerGreyColor
  border-bottom: 1px solid #EAEAEA
  z-index: 999
  position: fixed
  top: 0
  width: 100%
  background: white
  @include toH(700px)
    font-size: 12px
    line-height: 14px
  .info-block
    display: flex
    justify-content: space-between
    align-items: center
    min-height: 45px
    @include toH(700px)
      min-height: 30px
    .tel-email
      display: flex
      flex-wrap: wrap
      justify-content: flex-end
      margin-left: 10px
      color: #262626
      span
        margin-right: 5px
  &.it-mob
    border-bottom: none
    position: relative
    background: transparent
    width: auto
    .info-block
      display: block
      min-height: unset
      .tel-email
        display: flex
        flex-direction: column
        justify-content: flex-end
        margin: auto
        span
          margin-right: 5px
</style>
