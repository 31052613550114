export default {
  namespaced: true,
  state: {
    dialogs: {},
    asideOpen: false
  },
  getters: {
    getDialog: state => name => state.dialogs[name] || {},
    isAsideOpen: state => state.asideOpen
  },
  mutations: {
    setDialog: (state, { name, visible = false, data = {} }) => {
      state.dialogs = { ...state.dialogs, [name]: { visible, data } }
    },
    setAside: (state, bool = false) => (state.asideOpen = bool)
  }
}
