import { mapGetters } from 'vuex'
import { errHandlerHelper } from '@/helpers/errorHandler'
import {
  renderTimer,
  dataToObj,
  toLocaleDateTime,
  numToLocaleString
} from '@/helpers/functions'

export default {
  data() {
    const { width } = document.getElementById('app').getBoundingClientRect()
    const windowHeight = window?.outerHeight || 1000
    return {
      windowWidth: width,
      windowHeight
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile/userInfo',
      userRole: 'profile/userRole',
      isAuth: 'auth/isAuth',
      adminToken: 'auth/getAdminToken'
    }),
    isBidder() {
      return this.userRole === 'bidder'
    },
    isAnotherUser() {
      return this.adminToken && this.adminToken !== this.isAuth
    },
    isMobile() {
      return this.windowWidth <= 768
    },
    isTablet() {
      return this.windowWidth <= 1024
    },
    isSmallDesktop() {
      return this.windowWidth <= 1160
    }
  },
  mounted() {
    this.$nextTick(() => window.addEventListener('resize', this.handleResize))
  },
  methods: {
    errHandler(err) {
      errHandlerHelper(err)
    },
    updateObject(obj, data) {
      return dataToObj(obj, data)
    },
    handleResize() {
      const { width } = document.getElementById('app').getBoundingClientRect()
      const windowHeight = window?.outerHeight || 1000
      this.windowWidth = width
      this.windowHeight = windowHeight
    },
    getAdaptClass(key, className = '') {
      return [
        className,
        {
          [`${key}-tab`]: this.isTablet,
          [`${key}-mob`]: this.isMobile
        }
      ]
    },
    loadLocationFilter(point) {
      if (!point) return {}
      point = point.replace(/&nbps;/g, ' ')
      const [city, district] = point.split(', ')

      return { city, district }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  filters: {
    toTimer(timer, full = false, clock = true) {
      return renderTimer(timer, full, clock)
    },
    numToLocaleString(num) {
      return numToLocaleString(num)
    },
    toLocaleDateTime(date, format, timestamp) {
      return toLocaleDateTime(date, format, timestamp)
    }
  }
}
