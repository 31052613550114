import Repository from './Repository'
const action = 'profile'
const current = 'profile/current'

export default {
  getProfile() {
    return Repository.get(current)
  },
  updateProfile(data) {
    return Repository.post(action + '/edit', data)
  },
  sendConfirm() {
    return Repository.post(action + '/email')
  },
  checkConfirm(code) {
    return Repository.get(`${action}/email?code=${code}`)
  }
}
