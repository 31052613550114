<template>
  <section :class="getAdaptClass('hb', 'header-bottom bg-accepted')">
    <span v-if="isMobile" class="el-icon-own-menu" @click="openMobileMenu" />
    <el-row
      v-else
      type="flex"
      align="middle"
      justify="space-between"
      class="container h-100"
    >
      <header-menu />
      <auth-block />
    </el-row>
    <EnterModal />
    <UsersListModal />
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import HeaderMenu from './HeaderMenu'
import AuthBlock from './AuthBlock'
import EnterModal from './EnterModal'
import UsersListModal from './UsersListModal'

export default {
  name: 'HeaderBottom',
  components: { EnterModal, HeaderMenu, AuthBlock, UsersListModal },
  computed: {
    ...mapGetters('popups', ['isAsideOpen']),
    isOpenMobileMenu() {
      return this.isAsideOpen
    }
  },
  methods: {
    ...mapMutations('popups', ['setAside']),
    openMobileMenu() {
      this.setAside(!this.isOpenMobileMenu)
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.header-bottom
  height: 85px
  @include toH(700px)
    height: 45px
  &.hb-mob
    height: auto
    background: none
    .el-icon-own-menu
      width: 30px
      height: 25px
      cursor: pointer
      &:before
        content: ''
        position: absolute
        background: url("~@/assets/imgs/icons/menu-icon.svg") no-repeat
        background-size: contain
        width: 30px
        height: 25px
</style>
