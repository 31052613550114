<template>
  <section v-if="showPagination">
    <el-pagination
      :page-size="pagination.perPage"
      :page-count="pagination.pageCount"
      :pager-count="pager"
      :current-page="pagination.currentPage"
      :total="pagination.totalCount"
      layout="prev, pager, next"
      @current-change="onChange"
    />
  </section>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    pager: {
      type: Number,
      default: 7
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pagination() {
      return this.params && typeof this.params.totalCount !== undefined
        ? this.params
        : false
    },
    showPagination() {
      return (
        this.show ||
        (this.pagination &&
          (this.pagination.totalCount || 0) / (this.pagination.perPage || 1) >
            1)
      )
    }
  },
  methods: {
    onChange(page) {
      this.$emit('changed', page)
    }
  }
}
</script>
