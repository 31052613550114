<template>
  <div
    :class="['menu-item fs-18 t-grey cp', { 'is-active': isActive }]"
    @click="goTo(item.name)"
  >
    {{ item.title }} {{ item.span }}
    <span v-if="item.counts" class="ml-5">({{ count }})</span>
    <span v-if="item.active" class="ml-5"> ({{ activeTenders }})</span>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MobileMenuItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getCount: 'accreditations/getCount',
      activeTenders: 'tenders/getActiveCount'
    }),
    count() {
      return this.getCount(this.isBidder ? 'accepted' : 'pending')
    },
    isActive() {
      return this.$route.name === this.item.name
    }
  },
  methods: {
    ...mapMutations('popups', ['setAside']),
    goTo(name) {
      this.setAside(false)
      this.$router.push({ name })
    }
  }
}
</script>

<style lang="sass">
.menu-item
  padding: 19px 21px
  font-weight: 500
  background: $white
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.1)
  border-radius: 5px
  transition: background .3s ease
  &:hover, &:focus, &:active, &.is-active
    background: $bgTableStriped

  & + &
    margin-top: 21px
</style>
