import router from '@/router'
import ApiFactory from '@/api/ApiFactory'
import { errHandlerHelper, getError } from '@/helpers/errorHandler'
import * as action from '../actions/auth'
import { CLEAR_PROFILE } from '../actions/profile'
import { setLoading, getLoading } from '../common'
import {
  getAdminToken,
  getToken,
  removeTokens,
  setAdminToken,
  setToken
} from '@/helpers/localstorage'
import { helperGetDay } from '@/helpers/functions'

const AuthApi = ApiFactory.get('auth')
const token = getToken()
const adminToken = getAdminToken()
const initialState = {
  token: token || '',
  adminToken: adminToken || '',
  loading: false,
  loadingLogin: false,
  loadingReg: false,
  status: ''
}
const flag = true

const checkForErrors = (commit, data) => {
  const { status, message } = data
  let flag = false
  if (status === 'error') {
    commit(action.AUTH_ERROR, message)
    flag = true
  }
  return flag
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getLoading,
    isAuth: state => state.token,
    authStatus: state => state.status,
    getAdminToken: state => state.adminToken
  },
  actions: {
    sendSms: ({ commit }, login) => {
      commit('setLoading', { flag })
      return AuthApi.sms(login)
        .then(({ data }) => {
          const { time_to_resend, was_sent, message } = data
          let status = ''
          let text = message || 'Код отправлен по СМС.'
          if (!was_sent) {
            status = 'error'
            const minutes = helperGetDay(
              time_to_resend > 0 ? Math.ceil((time_to_resend / 60) % 60) : 0
            )
            text = 'Другой код был отправлен недавно. '
            commit(action.AUTH_ERROR, text)
            text += `Подождите ~${minutes} и нажмите получить код`
          }
          return { text, status, time_to_resend }
        })
        .catch(err => errHandlerHelper(err))
        .finally(() => commit('setLoading'))
    },
    login: ({ commit, dispatch }, { login, code }) => {
      const type = 'Login'
      commit('setLoading', { flag, type })
      AuthApi.login(login, code)
        .then(({ data }) => {
          data.redirect = true
          if (!checkForErrors(commit, data)) {
            dispatch(action.AUTH_READY, data)
            commit('popups/setDialog', { name: 'enter-modal' }, { root: true })
          }
        })
        .catch(err => {
          const message = getError(err, true) || 'Код введен не верно'
          commit(action.AUTH_ERROR, message)
          commit(`profile/${CLEAR_PROFILE}`, null, { root: true })
        })
        .finally(() => commit('setLoading', { type }))
    },
    preRegistration: ({ commit }, requestData) => {
      const type = 'Reg'
      commit('setLoading', { flag, type })
      return AuthApi.preRegister(requestData)
        .then(() => 'success')
        .catch(err => errHandlerHelper(err))
        .finally(() => commit('setLoading', { type }))
    },
    registration: ({ commit, dispatch }, requestData) => {
      const type = 'Reg'
      commit('setLoading', { flag, type })
      AuthApi.register(requestData)
        .then(({ data }) => {
          if (!checkForErrors(commit, data)) dispatch(action.AUTH_READY, data)
        })
        .catch(err => {
          console.log(err)
          const message = getError(err, true) || 'Код введен не верно'
          commit(action.AUTH_ERROR, message)
          commit(`profile/${CLEAR_PROFILE}`, null, { root: true })
        })
        .finally(() => commit('setLoading', { type }))
    },
    logout: ({ commit }) => {
      commit(`profile/${CLEAR_PROFILE}`, null, { root: true })
      commit(action.AUTH_LOGOUT)
      router.push({ name: 'Description' })
    },
    [action.AUTH_READY]: ({ commit, dispatch }, data) => {
      const { token, redirect, user_role } = data
      setToken(token)
      if (user_role === 'admin') {
        setAdminToken(token)
        commit(action.AUTH_ADMIN_SUCCESS, token)
      }
      dispatch('profile/getProfile', null, { root: true }).then(res => {
        if (res !== 'error') {
          commit(action.AUTH_SUCCESS, token)
          if (redirect) router.push({ name: 'Tenders' })
        }
      })
    }
  },
  mutations: {
    setLoading,
    [action.AUTH_SUCCESS]: (state, token) => {
      state.status = ''
      state.token = token
    },
    [action.AUTH_ADMIN_SUCCESS]: (state, token) => {
      state.adminToken = token
    },
    [action.AUTH_ERROR]: (state, message) => {
      removeTokens()
      state.status = message
    },
    [action.AUTH_LOGOUT]: state => {
      removeTokens()
      state.token = ''
      state.adminToken = ''
      state.status = ''
    },
    setStatus: (state, message = '') => {
      state.status = message
    }
  }
}
