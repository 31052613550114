import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalMixin from './mixins/globalMixin'

import '@/plugins'
import '@/assets/styles/main.sass'

Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.devtools = process.env.NODE_ENV !== 'production'

Vue.mixin(globalMixin)

new Vue({
  router,
  store,
  ...App
}).$mount('#app')
