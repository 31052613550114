<template>
  <section :class="getAdaptClass('ih', 'i-header')">
    <info-top />
    <logo />
    <header-bottom />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfoTop from './components/InfoTop'
import Logo from './components/Logo'
import HeaderBottom from './components/HeaderBottom'

export default {
  name: 'Header',
  components: { InfoTop, Logo, HeaderBottom },
  created() {
    if (this.isAuth && !this.user.id) this.getProfile()
  },
  watch: {
    isAuth: {
      immediate: true,
      async handler(auth) {
        if (auth) {
          if (!this.checkLibs)
            await this.fetchLibs({ 'per-page': 9999, show_as_vendor: 1 })
          this.FETCH_COUNTS()
          this.FETCH_TOTAL_COUNTERS()
          this.fetchCounters()
        } else {
          clearInterval(this.countersInterval)
        }
      }
    }
  },
  data() {
    return {
      countersInterval: null
    }
  },
  computed: {
    ...mapGetters('libs', ['checkLibs'])
  },
  methods: {
    ...mapActions({
      getProfile: 'profile/getProfile',
      fetchLibs: 'libs/fetchLibs',
      FETCH_COUNTS: 'accreditations/FETCH_COUNTS',
      FETCH_TOTAL_COUNTERS: 'tenders/FETCH_TOTAL_COUNTERS'
    }),
    fetchCounters() {
      this.countersInterval = setInterval(() => {
        this.FETCH_COUNTS()
        this.FETCH_TOTAL_COUNTERS()
      }, 60000)
    }
  },
  beforeDestroy() {
    clearInterval(this.countersInterval)
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.i-header
  display: flex
  flex-direction: column
  padding-top: 46px
  @include toH(700px)
    font-size: 12px
    line-height: 14px
    padding-top: 31px
  &.ih-mob
    flex-direction: row-reverse
    align-items: center
    justify-content: space-between
    padding: 5px 15px
    border-bottom: 1px solid #EAEAEA
    height: 100%
</style>
