import { commonApiActions } from './commonApi'
import authApi from './authApi'
import profileApi from './profileApi'
import libsApi from './libsApi'
import registryApi from './registryApi'
import usersApi from './usersApi'
import tendersApi from './tendersApi'
import accreditationsApi from './accreditationsApi'
import invitationsApi from './invitationsApi'

const repositories = {
  auth: authApi,
  profile: profileApi,
  libs: libsApi,
  registry: registryApi,
  users: commonApiActions('users', usersApi),
  tenders: commonApiActions('tenders', tendersApi),
  offers: commonApiActions('tender-offers'),
  accreditations: commonApiActions('accreditations', accreditationsApi),
  biddersGroups: commonApiActions('bidders-groups'),
  approvedUsers: commonApiActions('profile/approved-users'),
  contactCarriers: commonApiActions('contacts/carriers'),
  contactCustomers: commonApiActions('contacts/customers'),
  products: commonApiActions('products'),
  consignees: commonApiActions('consignees'),
  invitations: commonApiActions('invitations', invitationsApi)
}

export default {
  get: name => repositories[name]
}
